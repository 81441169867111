@import "../_shared";

.playlist{
  position:relative;
  padding: 1em;
  &:not(.radio){
    padding-bottom:6em; /*for radios, we'll do the same but on the radio pagination. To fix make it more consistent ? Should be on a .has-player page.*/
  }

  header{
    display:flex;
    margin-bottom: 1em;

    .post-meta-radio{
      width:2em;
      height:2em;
      font-size:.2em;
      margin-right:.5em;
      display:inline-block;
    }

    .playlist-image{
      background-color:#CCC;
      width:10em;
      height:10em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .playlist-header-content{
      flex:1;
      padding:0 1em;
      display: flex;
      flex-direction: column;
      .playlist-title.handwritten{
        font-size: 4em;
      }
    }



  }
}
